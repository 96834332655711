import { CookieOptionsKeys, Env } from "~/types/entities";

export default defineNuxtPlugin(() => {
  const { cookiePolicyKey, env } = useRuntimeConfig().public;
  if (env !== Env.production) return

  const cookie = getParsedCookie(cookiePolicyKey);
  if(!cookie) return

  if (cookie[CookieOptionsKeys.Analytical]) {
    useGtag().initialize();
  }
});
