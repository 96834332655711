import { CountryData, LevelsList } from "~/types/statistics";

const changeLevelOrderForBY = (countryData: CountryData): CountryData => {
  const newLevels: Partial<LevelsList> = {
    platinum: undefined,
    gold: undefined,
    silver: undefined,
    region: undefined,
  };
  const { levels, statistics } = countryData;

  for (let level in newLevels) {
    newLevels[level] = levels[level];
  }

  return {
    levels: <Required<LevelsList>>newLevels,
    statistics,
  };
};

export async function getStatistics(locale: string): Promise<CountryData> {
  const res = await import("public/json/statistics.json").then(
    (m) => m.default || m
  );

  return (
    changeLevelOrderForBY(res?.country?.["by"]) ?? {
      facility_amount: undefined,
      activity_amount: undefined,
    }
  );
}
