export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"type":"text/javascript","innerHTML":"\n                document.umnicoWidgetHash = \"5992077fdfdd98f234d4b7c161748866\";\n                var x = document.createElement('script');\n                x.src = 'https://umnico.com/assets/widget-loader.js';\n                x.type = 'text/javascript';\n                x.charset = 'UTF-8';\n                x.async = true;\n                document.addEventListener('DOMContentLoaded', function() {\n                  document.body.appendChild(x);\n                });\n              ","defer":true}],"noscript":[],"base":{"href":"/"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false