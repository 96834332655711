
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';


export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = {};

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt });
  vueApp.use(ToastService);
  vueApp.directive('tooltip', Tooltip);
});
        