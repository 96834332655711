
// @ts-nocheck


export const localeCodes =  [
  "ru-by",
  "en-by"
]

export const localeLoaders = {
  "ru-by": [{ key: "../i18n/localizations/ru.json", load: () => import("../i18n/localizations/ru.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_i18n_localizations_ru_json" */), cache: true },
{ key: "../i18n/locales/ru-by.json", load: () => import("../i18n/locales/ru-by.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_i18n_locales_ru_by_json" */), cache: true },
{ key: "../i18n/localizations/ru_delete_later.json", load: () => import("../i18n/localizations/ru_delete_later.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_i18n_localizations_ru_delete_later_json" */), cache: true }],
  "en-by": [{ key: "../i18n/localizations/en.json", load: () => import("../i18n/localizations/en.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_i18n_localizations_en_json" */), cache: true },
{ key: "../i18n/locales/en-by.json", load: () => import("../i18n/locales/en-by.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_i18n_locales_en_by_json" */), cache: true },
{ key: "../i18n/localizations/en_delete_later.json", load: () => import("../i18n/localizations/en_delete_later.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_i18n_localizations_en_delete_later_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "__i18n_i18n_config_ts_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./server/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/i18n.config.ts",
  "locales": [
    {
      "code": "ru-by",
      "iso": "ru-by",
      "dir": "ltr",
      "country": "by",
      "language": "ru",
      "files": [
        "i18n/localizations/ru.json",
        "i18n/locales/ru-by.json",
        "i18n/localizations/ru_delete_later.json"
      ]
    },
    {
      "code": "en-by",
      "iso": "en-by",
      "dir": "ltr",
      "country": "by",
      "language": "en",
      "files": [
        "i18n/localizations/en.json",
        "i18n/locales/en-by.json",
        "i18n/localizations/en_delete_later.json"
      ]
    }
  ],
  "defaultLocale": "ru-by",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "i18n",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ru-by",
    "iso": "ru-by",
    "dir": "ltr",
    "country": "by",
    "language": "ru",
    "files": [
      {
        "path": "i18n/localizations/ru.json"
      },
      {
        "path": "i18n/locales/ru-by.json"
      },
      {
        "path": "i18n/localizations/ru_delete_later.json"
      }
    ]
  },
  {
    "code": "en-by",
    "iso": "en-by",
    "dir": "ltr",
    "country": "by",
    "language": "en",
    "files": [
      {
        "path": "i18n/localizations/en.json"
      },
      {
        "path": "i18n/locales/en-by.json"
      },
      {
        "path": "i18n/localizations/en_delete_later.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
