<template>
  <header ref="header" class="header" :class="headerClass" tabindex="0">
    <div class="header__container">
      <LocLink to="/" class="header-navbar__logo">
        <img :src="`image/logo/logo-${brand}.svg`" :alt="brand" />
      </LocLink>

      <nav class="header-navbar">
        <ul class="navbar-list">
          <li>
            <LocLink class="navbar-list__link text-16-500" to="/facilities">
              {{ $t("Facilities") }}
            </LocLink>
          </li>
          <li>
            <LocLink class="navbar-list__link text-16-500" to="/levels">
              {{ $t("Memberships") }}
            </LocLink>
          </li>
          <LocLink class="navbar-list__link text-16-500" to="/companies">
            {{ $t("For Companies") }}
          </LocLink>
          <LocLink class="navbar-list__link text-16-500" to="/partners">
            {{ $t("For Partners") }}
          </LocLink>
          <li>
            <LocLink class="navbar-list__link text-16-500" to="/contacts">
              {{ $t("Contacts") }}
            </LocLink>
          </li>
        </ul>
      </nav>
      <div class="header-controls">
        <BaseButton
          id="offer-btn"
          class="header-controls__offer"
          small
          :outlined="isOutlinedButton"
          @click="showGetAnOfferFormModal"
        >
          {{ $t("Get an Offer") }}
        </BaseButton>
        <LazyModalsGetAnOfferFormModal
          v-if="isShowGetAnOfferFormModal"
          v-model:is-show-modal="isShowGetAnOfferFormModal"
        />
        <div
          id="toggle-sidebar"
          tabindex="0"
          class="header-controls__menu"
          :class="showSidebarClass"
          @click.stop="toggleSidebar"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ClientOnly>
          <Teleport to="body">
            <LazySidebarTheSidebar
              :show-sidebar="showSidebar"
              :class="headerClass"
              :brand="brand"
              @close="closeSidebar"
            />
          </Teleport>
        </ClientOnly>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import LocLink from "~/components/lang/LocLink.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import BaseButton from "~/components/button/BaseButton.vue";

export default defineNuxtComponent({
  components: {
    LocLink,
    BaseIcon,
    BaseButton,
  },
  setup() {
    const { gtag } = useGtag();
    const route = useRoute();
    const { brand } = useRuntimeConfig().public;
    const { locale } = useI18n();

    const isScrollMode = ref(false);
    const isFacilitiesPage = computed(() => {
      const regexp = new RegExp(`^/${locale.value}/facilities$`, "g");
      return route.path.match(regexp);
    });
    const isOutlinedButton = computed(() => {
      return !isFacilitiesPage.value && !isScrollMode.value;
    });
    const header = ref<HTMLElement | null>(null);
    const headerClass = computed(() => ({
      "scroll-mode":
        (isFacilitiesPage.value || isScrollMode.value) && !showSidebar.value,
    }));
    const isShowGetAnOfferFormModal = ref(false);

    function showGetAnOfferFormModal() {
      gtag("event", HeaderEvents.getOfferModal);
      isShowGetAnOfferFormModal.value = true;
    }

    const showSidebar = ref(false);
    const showSidebarClass = computed(() =>
      showSidebar.value ? "opened" : ""
    );
    function toggleSidebar() {
      showSidebar.value = !showSidebar.value;
    }
    function closeSidebar() {
      showSidebar.value = false;
    }

    let observer: IntersectionObserver | null = null;

    function createObserver(): void {
      observer = new IntersectionObserver(
        ([event]) => {
          if (event.intersectionRatio < 1) {
            isScrollMode.value = true;
          } else {
            isScrollMode.value = false;
          }
        },
        { threshold: [1] }
      );

      if (header.value) {
        observer.observe(header.value);
      }
    }

    onMounted(createObserver);

    onUnmounted(() => {
      if (header.value) {
        observer?.unobserve(header.value);
      }
    });

    return {
      brand,
      headerClass,
      header,
      isScrollMode,
      isFacilitiesPage,
      isOutlinedButton,
      isShowGetAnOfferFormModal,
      showSidebar,
      showSidebarClass,
      showGetAnOfferFormModal,
      toggleSidebar,
      closeSidebar,
    };
  },
});
</script>
